<template>
  <div class="set-container">
    <!-- 重制密码 -->
    <div class="col-center">
      <div
        @click="$router.push('/resetPwd')"
        class="set-pwd-item row-between"
        style="margin-top:1.25rem;"
      >
        <div class="row-center">
          <!-- <img
            class="set-pwd-img"
            src="../assets/icon/my/set-pwd.png"
          /> -->
          <p
            style="margin-left: 18px;"
            class="set-pwd-title"
          >资产密码设置</p>

        </div>
        <img
          class="next-img"
          src="../assets/icon/next.png"
        />
      </div>
      <!-- 收货地址管理 -->
      <div
        @click="setAdressManageEdit(false)"
        class="set-pwd-item row-between"
        style="margin-top:1.25rem;"
      >
        <div class="row-center">
          <!-- <img
            class="set-pwd-img"
            src="../assets/icon/log-off.png"
          /> -->
          <p
            style="margin-left: 18px;"
            class="set-pwd-title"
          >收货地址管理</p>

        </div>
        <img
          class="next-img"
          src="../assets/icon/next.png"
        />
      </div>
      <!-- 注销 -->
      <div
        @click="$router.push('/logOff?from=h5')"
        class="set-pwd-item row-between"
        style="margin-top:1.25rem;"
      >
        <div class="row-center">
          <!-- <img
            class="set-pwd-img"
            src="../assets/icon/log-off.png"
          /> -->
          <p
            style="margin-left: 18px;"
            class="set-pwd-title"
          >注销账户</p>

        </div>
        <img
          class="next-img"
          src="../assets/icon/next.png"
        />
      </div>
      <van-button
        v-if="isShowLogout"
        @click="setVisible = true"
        type="danger"
      >退出登录</van-button>
    </div>
    <el-dialog
      title="确认要退出登录吗"
      :visible.sync="setVisible"
      @confirm="logOut"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="setVisible = false">取 消</el-button>
        <div
          @click="logOut"
          class="confirm-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Vue from 'vue';
import api from '../api/index-client'
import { Button } from 'vant';
import { Dialog } from 'element-ui';
Vue.use(Button);
Vue.use(Dialog);
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      setVisible: false,
      isShowLogout: false,
    }
  },
  mounted () {
    this.isShowLogout = Cookies.get('Device') ? false : true
    this.$store.commit('HIDE_APPLOADING')
  },
  methods: {
    setAdressManageEdit (e) {
      this.$store.commit('SET_ADREESSMANAGEEDIT',e)
      this.$router.push('/adressManage')
    },
    // 退出登录
    logOut () {
      api
        .post('logout')
        .then(result => {
          if (result.data.success) {
            Cookies.remove('Login')
            this.$router.replace('/login')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })

    }
  },
}
</script>
<style lang="less" scoped>
.set-container {
  margin-top: 3.875rem;
  height: calc(100vh - 3.875rem);
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .col-center {
    .set-pwd-item {
      width: 90%;
      height: 3.125rem;
      background: #262728;
      border-radius: 8px;
      margin: 0.75rem 5% 0 5%;
      .set-pwd-img {
        width: 1.25rem;
        height: auto;
        margin-left: 0.875rem;
        margin-right: 0.8125rem;
      }
      p {
        font-size: 0.875rem;
        font-weight: 500;
        color: #f0f0f0;
        line-height: 1.25rem;
      }
      .next-img {
        width: 0.5rem;
        height: auto;
        margin-right: 1rem;
      }
    }
    .van-button--danger {
      width: 80%;
      height: 2.875rem;
      position: absolute;
      bottom: 2.5rem;
      left: 10%;
      font-size: 1rem;
      font-family: lantinghei;
      font-weight: normal;
      color: #ffffff;
      line-height: 1.375rem;
      letter-spacing: 0.125rem;
    }
  }
}
</style>